import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import {
  FaCalendarDay,
  FaLaptop,
  FaRegClipboard,
  FaClock,
  FaServer,
  FaKeyboard,
  FaPassport,
  FaLock,
  FaCheck,
  FaExchangeAlt,
  FaFileCode,
  FaMobile,
  FaSlidersH,
  FaHeadphonesAlt,
  FaUserTie,
  FaDollarSign,
  FaLaptopCode,
  FaListUl,
  FaArrowsAlt,
} from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import Hero from "../components/HeroBanner";
import * as styles from "./index.module.css";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Lightbox from "../components/lightBox";
import {
FcComboChart,
FcTimeline,
FcPaid,
} from "react-icons/fc";



export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    synergius: file(relativePath: { eq: "Synergius-CRM-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    laptopcrm: file(relativePath: { eq: "laptop-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
     laptopsm: file(relativePath: { eq: "laptop-sm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
     laptopb2b: file(relativePath: { eq: "laptop-b2b.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    porownanie: file(relativePath: { eq: "gotowyvsdedykowany.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;


const Dedykowaneee = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Oprogramowanie dla firm - Zarządzanie firmą"
        description="Systemy dla firm ✅ Systemy CRM • Platformy B2B • Dedykowane rozwiązania dla biznesu • Systemy obsługi zleceń "
      />
         <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Rozwiązania dedykowane - oprogramowanie dla firm</h1>
        <h2>Wsparcie kluczowych procesów przedsiębiorstwa</h2>
      </Hero>
   <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz umówić się na prezentację możliwości systemu?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby skorzystać z&nbsp;niezobowiązującego doradztwa 
          w&nbsp;zakresie systemów do zarządzania firmą
</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę umówić się na prezentację
            </Link>
          </div>
          </div>
    </section>
       <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
Oferowane przez nas <strong>oprogramowanie dla firm</strong> powstało i&nbsp;rozwija 
się w&nbsp;efekcie zdobywanego przez nas doświadczenia przy realizacji projektów dla 
dużych przedsiębiorstw. Współpracujemy z&nbsp;wieloma liderami różnorodnych branż, 
dlatego nasze oprogramowanie ma szansę rozwijać się w&nbsp;wielu kierunkach. Dbając 
o&nbsp;jego elastyczność, dostarczamy konkretnym biznesom wyłącznie te możliwości, 
które są w&nbsp;nich potrzebne i znajdują swoje zastosowanie. 
</p>
<p>W&nbsp;zakresie kompleksowego zarządzania firmą oferujemy dedykowany 
system – <a href="https://aurabusiness.pl/">Aura Business</a> – przeznaczony dla dużych 
przedsiębiorstw, wymagających podmiotów, niszowych branż czy klientów, którzy oczekują 
niestandardowych rozwiązań.</p>
</div>
</div>
</Container>

   <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
 <h2
 style={{
  paddingTop:"10px",
  paddingBottom:"30px",
}}
  >Obszary, które wspiera nasze oprogramowanie dla firm</h2>
</div>

</div>
</Container>
<Container className="container container--text">
         <div className="text-block">
<h3>Sprzedaż i&nbsp;marketing</h3>

<p>Aura Business wspomaga organizacje w&nbsp;kompleksowym zarządzaniu obszarem sprzedaży. 
Dostarczamy szefom i&nbsp;dyrektorom sprzedaży narzędzie, którego zadaniem jest wsparcie ich
w&nbsp;osiąganiu coraz ambitniejszych celów sprzedażowych, a&nbsp;także poozwalające:</p>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
<ul>
<li>oszczędzić czas dzięki automatycznym i&nbsp;zawsze aktualnym raportom,</li>
<li>zautomatyzować powtarzalne czynności,</li>
<li>uporządkować pracę działu sprzedaży,</li>
<li>zadbać o&nbsp;wzorową zastępowalność,</li>
<li>usprawnić proces wdrażania nowych osób,</li>
<li>wprowadzić jeden standard pozyskiwania i&nbsp;obsługi klienta,</li>
<li>monitorować proces sprzedaży i&nbsp;aktywności handlowców,</li>
<li>analizować powody utraty szans sprzedaży i&nbsp;szybko zauważać wąskie gardła,</li>
<li>wyznaczać ambitne cele i&nbsp;monitorować ich realizację,</li>
<li>zadbać o&nbsp;porządek informacji dotyczących współpracy z&nbsp;kontrahentami, </li>
<li>wprowadzić jeden spójny standard ofertowania, </li>
<li>uporządkować informacje o&nbsp;cenach i&nbsp;stanach magazynowych produktów.</li>
</ul>
</p>
</div>
 <div style={{ 
  paddingTop:"35px",

 }} className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.laptopcrm]}
        alts={[
          "Systemy CRM Questy",
        ]}
      />
    </div>
</div>
</Container>
<Container className="container container--text">
         <div className="text-block">
<p>Dostarczane przez nas oprogramowanie dla firm w&nbsp;obszarze sprzedaży to narzędzie 
również dla pracowników działu handlowego, który zyskują dzięki niemu pomoc 
w&nbsp;usprawnieniu swoich działań. Zaprojektowany proces sprzedaży obrazowany przez CRM jest
ich codziennym wspraciem, dzięki któremu wiedzą czym oraz w&nbsp;jakiej kolejności 
powinni się zająć.
</p>
</div>
</Container>
 <Container className="container container--text">
         <div className="text-block">
<h3>Zarządzanie usługami</h3>

<p>Drugim kluczowym obszarem systemu Aura Business jest zarządzanie usługami. Stworzyliśmy 
to oprogramowanie dla firmy, aby wspierać przedsiębiorstwa nie tylko na etapie 
pozyskiwania przez nie klientów, partnerów czy dostawców, ale także na etapie realizacji współpracy.
</p>
<p>System wspiera organizację w&nbsp;zarządzaniu projektami lub wieloma zleceniami 
jednocześnie. Pozwala na obsługę procesów różnego typu – od skomplikowanych i&nbsp;złożonych 
projektów, np. budowy obiektów, aż po obsługę prostych zleceń montażowych, serwisowych, 
gwarancyjnych – a&nbsp;w&nbsp;zasadzie dowolnych. 
</p>
<p>Wspieramy dyrektorów działów realizacji, serwisu czy obsługi posprzedażowej w, między 
innymi:
</p>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
    <div style={{ 
  paddingTop:"35px",

 }} className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.laptopsm]}
        alts={[
          "Systemy do serwisu Questy",
        ]}
      />
    </div>
         <div className="text-block">
        
<p>
<ul>
<li>organizacji pracy działu lub wielu działów i&nbsp;brygad,</li>
<li>uporządkowaniu wiedzy o&nbsp;klientach i&nbsp;zleceniach,</li>
<li>stworzeniu wspólnej bazy wiedzy o&nbsp;serwisowanych maszynach lub urządzeniach, która 
pozwala pracownikom dzielić się informacjami i&nbsp;poprawić ich efektywność przy kolejnych zleceniach,</li>
<li>szybkim delegowaniu zleceń,</li>
<li>sprawnym monitorowaniu: zleceń zaplanowanych, przekazanych do realizacji lub zakończonych,</li>
<li>zarządzaniu zarówno pracownikami, którzy pracują stacjonarnie, jak i&nbsp;w&nbsp;terenie,</li>
<li>mierzeniu rentowności współpracy z&nbsp;danym kontrahentem,</li>
<li>monitorowaniu pracochłonności zleceń,</li>
<li>analizowaniu wydajności pracy. </li>
</ul>
</p>
</div>
</div>
</Container>
<Container className="container container--text">
         <div className="text-block">
<p>Pracownikom realizującym zlecenia oprogramowanie pomaga wykonywać obowiązki sprawniej. 
Zyskują oni bowiem narzędzie, które prowadzi ich w&nbsp;kolejności do poszczególnych zleceń, 
a nawet konkretnych zadaniach do wykonania w&nbsp;ramach danego zlecenia. Wszyscy 
pracownicy współdzielą bazę wiedzy o&nbsp;serwisowanych maszynach i&nbsp;urządzeniach, co 
pozwala im szybciej zweryfikować i&nbsp;rozwiązać ewentualny problem.</p>
</div>
</Container>
<Container className="container container--text">
         <div className="text-block">
<h3>Sprzedaż hurtowa online i&nbsp;samoobsługa klienta biznesowego</h3>

<p>Nasze oprogramowanie dla firmy wspiera także trzeci kluczowy obszar działalności, 
czyli sprzedaż B2B. Przeznaczone jest ono dla producentów, dystrybutorów i&nbsp;hurtowników, 
którzy chcą rozwinąć swoją sprzedaż hurtową o&nbsp;kanał sprzedaży internetowej. W&nbsp;tym 
obszarze nasze oprogramowanie rozwija się o&nbsp;platformę umożliwiającą wyjście do klienta 
B2B z&nbsp;ofertą produktową 24 godziny na dobę przez 7 dni w&nbsp;tygodniu. Kontrahent 
samodzielnie może zalogować się do systemu, aby zobaczyć przeznaczone dla niego produkty 
po indywidualnych cenach lub rabatach i&nbsp;złożyć zamówienie w&nbsp;dowolnym momencie. 
</p>

</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
 
        <div className="text-block">

<p>W ten sposób wspieramy firmę w:</p>
<p>
<ul>
<li>automatyzacji procesu obsługi klienta,</li>
<li>samoobsłudze klienta na etapie składania zamówień, zapytań ofertowych, negocjowania czy pobierania dokumentów handlowych,</li>
<li>dostarczeniu dedykowanej oferty dla konkretnych kontrahentów,</li>
<li>oszczędności czasu pracowników i&nbsp;obniżeniu kosztu obsługi zamówień,</li>
<li>dotarciu do większej grupy klientów w&nbsp;krótszym czasie,</li>
<li>usprawnieniu komunikacji z&nbsp;klientami.</li>
</ul>
</p>
</div>
 <div style={{ 
  paddingTop:"20px",

 }} className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.laptopb2b]}
        alts={[
          "Platformy B2B Questy",
        ]}
      />
    </div>
</div>
</Container>
 <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz umówić się na prezentację możliwości systemu?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby skorzystać z&nbsp;niezobowiązującego doradztwa 
          w&nbsp;zakresie systemów do zarządzania firmą

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę umówić się na prezentację
            </Link>
          </div>
          </div>
    </section>
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Oprogramowanie dla firm Aura Business</h2>
<Container>
 <div className="container container--text">
<p>To aż trzy obszary, które mogą działać niezależnie od siebie lub być dostarczone
 w&nbsp;jednym, spójnym rozwiązaniu. <strong>Jeszcze więcej o&nbsp;każdym z&nbsp;nich 
 możesz przeczytać wybierając jeden z&nbsp;poniższych kafelków</strong>.</p>
<p>Pamiętaj też, że to nie wszystko. <strong>Jeżeli Twoje potrzeby wykraczają poza 
opisany tutaj zakres, np. potrzebujesz konfiguratora produktów – w&nbsp;każdej chwili 
możesz skontaktować się z&nbsp;nami</strong>. Chętnie wysłuchamy Twoich potrzeb 
i&nbsp;stworzymy rozwiązania skrojone na Twoją firmę.</p>
</div>
</Container>


  <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
        
          <Link to="/sprzedaz-i-marketing/"><Tile to="/sprzedaz-i-marketing/">
            <FcComboChart size="70px" />
            <br />
            <h3>Sprzedaż i marketing</h3>
          </Tile></Link>
           <Link to="/zarzadzanie-uslugami/"><Tile to="/zarzadzanie-uslugami">
            <FcTimeline size="70px" />
            <br />
            <h3>Zarządzanie usługami</h3>
          </Tile></Link>
          <Link to="/samoobsluga-b2b/"><Tile to="/samoobsluga-b2b/">
            <FcPaid size="70px" color="#999999" />
            <br />
            <h3>Sprzedaż hurtowa online</h3>
          </Tile></Link>
        </div>
      </Container>

      <h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Czym różni się program Aura Business od standardowych rozwiązań naszej firmy?</h2>

<h3
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Zacznij działać na własnych zasadach</h3>

<Container>
 <div className="container container--text">
<p>Aura Business jako rozwiązanie dedykowane może być rozszerzoną alternatywą dla naszych
standardowych programów. Posiadając szersze możliwości idealnie sprawdza się w&nbsp;przedsiębiorstwach
z&nbsp;większymi wymaganiami i&nbsp;potrzebami, ale wiąże się również z&nbsp;większym budżetem.</p>

<p>
Nie masz obecnie wystarczającego budżetu? Twoje potrzeby nie są na razie zaawansowane? 
Przewidujesz jednak rozwój i&nbsp;w&nbsp;przyszłości rozwiązanie Aura Business byłoby 
świetnym wsparciem?
</p>

<p>
Nasze rozwiązania charakteryzują się ogromną skalowalnością. Z&nbsp;tego względu możesz 
zacząć pracę z&nbsp;systemem informatycznym na swoich własnych zasadach. Możesz od razu
zdecydować się na wdrożenie Aura Business, ale istnieje również druga opcja. Bez problemu
możesz rozpocząć od jednego lub kilku naszych gotowych rozwiązań działających w&nbsp;sposób
standardowy, a&nbsp;następnie dokonać z&nbsp;naszym wsparcie przejścia na system dedykowany, 
dopasowany do Twoich potrzeb.
</p>

</div>
</Container>

<h3
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Dlaczego warto wybrać rozwiązanie dedykowane?</h3>
<Container>
 <div className="container container--text">
 <p>Wiele firm poszukujących wsparcia informatycznego stoi przed niemałym wyzwaniem 
 dotyczącym wyboru odpowiedniego systemu. Często pojawiająca się wątpliwość dotyczy 
 decyzji odnośnie rodzaju oprogramowania. Na szali może stać standardowe rozwiązanie 
 szybkie do wdrożenia, a&nbsp;także indywidualny system dopasowywany do potrzeb.</p>
 <center><p>
Jaką przewagę daje Tobie ten drugi?
</p></center>
<Container className="container container--text">
    <div className="text-answers">
        <div className="text-block">
<p>
<ul>
<li>Zyskujesz elastyczność i indywidualne podejście w zakresie zmian, konfiguracji 
i niestandardowych mechanizmów działania.</li>
<li>Masz dostęp do szerszej oferty funkcjonalności.</li>
<li>Obsługujesz dodatkowe procesy związane ze sprzedażą, marketingiem oraz serwisem.</li>
<li>Wykorzystujesz niestandardowy design platformy informatycznej.</li>
<li>Samodzielnie rozwijasz system dzięki narzędziu Developer KIT.</li>
<li>Rozbudowujesz zakres integracji z Twoim programem ERP.</li>
<li>Integrujesz system z dowolnymi używanymi w Twojej firmie programami IT.</li>
<li>Ponosisz mniejsze koszty związane z przejściem na wyższe rozwiązania niż w przypadku 
poszukiwania nowego systemu z większymi możliwościami.</li>
<li>Nie masz konieczności ponownego szkolenia pracowników.</li>
<li>Zyskujesz nowe możliwości rozwojowe.</li>
</ul>
</p>
</div>
<div className="text-block">
<div style={{ 
  paddingTop:"0px",

 }} className="text-block">
    <Lightbox
        style={{
          maxWidth: 400,
          margin: "0 auto",
        }}
        images={[data.porownanie]}
        alts={[
          "System gotowy vs. dedykowany",
        ]}
      />
    </div>
</div>
</div>
</Container>


</div>
</Container>


  <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz umówić się na prezentację możliwości systemu?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby skorzystać z&nbsp;niezobowiązującego doradztwa 
          w&nbsp;zakresie systemów do zarządzania firmą

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę umówić się na prezentację
            </Link>
          </div>
          </div>
    </section>
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Co wyróżnia się nasze oprogramowanie dla firmy?</h2>
 <Container>
      <BenefitBlockWrapper>
       <BenefitBlock
          icon={<FaArrowsAlt size="70px" color="#86BC2A" />}
          title="Pełna skalowalność"
        />
                <BenefitBlock
          icon={<FaSlidersH size="70px" color="#86BC2A" />}
          title="Elastyczność systemu"
        />
        <BenefitBlock
          icon={<FaHeadphonesAlt size="70px" color="#86BC2A" />}
          title="Sprawne wsparcie powdrożeniowe"
        />
        <BenefitBlock
          icon={<FaUserTie size="70px" color="#86BC2A" />}
          title="Doradztwo biznesowe"
        />
      </BenefitBlockWrapper>
        <BenefitBlockWrapper>
       <BenefitBlock
          icon={<FaExchangeAlt size="70px" color="#86BC2A" />}
          title="Łatwość integracji z&nbsp;innymi narzędziami IT"
        />
                <BenefitBlock
          icon={<FaLaptopCode size="70px" color="#86BC2A" />}
          title="Najnowocześniejsze technologie"
        />
        <BenefitBlock
          icon={<FaListUl size="70px" color="#86BC2A" />}
          title="Rozliczenie etapowe"
        />
        <BenefitBlock
          icon={<FaDollarSign size="70px" color="#86BC2A" />}
          title="Elastyczne formy finansowania"
        />
      </BenefitBlockWrapper>
    </Container>
    <h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Z jakimi systemami integrujemy nasze oprogramowanie?</h2>

 <Container className="container container--text">
  <p>Integrowalność to jedna z&nbsp;najważniejszych cech wszystkich naszych rozwiązań. 
Aura Business integruje się z&nbsp;każdym popularnym systemem ERP, a&nbsp;także innymi 
programami wykorzystywanymi w&nbsp;przedsiębiorstwie, 
np. marketing automation. Poniżej znajduje się lista rozwiązań w&nbsp;których możemy 
pochwalić się doświadczeniem w&nbsp;zakresie integracji:
</p>
    <div className="text-answers">
         <div className="text-block">

<p><ul>
<li>Comarch ERP XL</li>
<li>Comarch Optima</li>
<li>Symfonia ERP</li>
<li>Subiekt GT</li>
<li>Subiekt Nexo</li>
<li>WAPRO ERP</li>
<li>Navireo</li>
</ul>
</p>
</div>
<div className="text-block">
<p>
<ul>
<li>Enova</li>
<li>SAP ERP</li>
<li>Impuls ERP</li>
<li>Microsoft Dynamics</li>
<li>IFS ERP</li>
<li>Exact ERP</li>
<li>Streamsoft</li>
</ul></p>
</div>
  <div className="text-block">
<p><ul>

<li>PayU</li>
<li>SMSAPI</li>
<li>Active Directory</li>
<li>GUS</li>
<li>Microsoft Exchange</li>
<li>CalDav</li>
<li>PrintFleet</li>
</ul></p>          
</div>
</div>
<p>Twojego systemu nie ma liście? Porozmawiajmy o&nbsp;potrzebach Twojej organizacji – jest 
duża szansa, że będziemy mogli stworzyć specjalnie dla Was integrację naszych systemów 
z&nbsp;wybranym oprogramowaniem. 
</p>
</Container>

 <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz umówić się na prezentację możliwości systemu?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby skorzystać z niezobowiązującego doradztwa w zakresie systemów do zarządzania firmą

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę umówić się na prezentację
            </Link>
          </div>
          </div>
    </section>
    </Layout>
  );
};

export default Dedykowaneee;
